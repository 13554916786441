"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.function.name.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.string.trim.js");

require("core-js/modules/es.json.stringify.js");

var _auth = require("@/utils/auth");

var _router = require("@/router");

var _basebasic = require("@/api/basebasic");

var getDefaultState = function getDefaultState() {
  return {
    token: (0, _auth.getToken)(),
    name: '',
    avatar: '',
    subSystem: [],
    hospitalName: '',
    activeSystemName: [],
    menus: [],
    // JSON.parse(localStorage.getItem('nowmenus')),
    roles: [],
    permissions: [],
    user_id: ''
  };
};

var state = getDefaultState();
var mutations = {
  RESET_STATE: function RESET_STATE(state) {
    Object.assign(state, getDefaultState());
  },
  SET_TOKEN: function SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_USER_ID: function SET_USER_ID(state, id) {
    state.user_id = id;
  },
  SET_NAME: function SET_NAME(state, name) {
    state.name = name;
  },
  SET_ROLES: function SET_ROLES(state, roles) {
    state.roles = roles;
  },
  SET_TEMPNAME: function SET_TEMPNAME(state, name) {
    state.name = name;
  },
  SET_AVATAR: function SET_AVATAR(state, avatar) {
    state.avatar = avatar;
  },
  SET_SUBSYSTEM: function SET_SUBSYSTEM(state, subSystem) {
    state.subSystem = subSystem;
  },
  SET_ACTIVESYSTEMNAME: function SET_ACTIVESYSTEMNAME(state, activeSystemName) {
    state.activeSystemName = activeSystemName;
  },
  SET_MENUS: function SET_MENUS(state, menus) {
    state.menus = menus;
  },
  SET_PERMISSIONS: function SET_PERMISSIONS(state, permissions) {
    state.permissions = permissions;
  },
  SET_HOSPITALNAME: function SET_HOSPITALNAME(state, hospitalName) {
    state.hospitalName = hospitalName;
  }
};
var actions = {
  userlogin: function userlogin(_ref, loginInfo) {
    var commit = _ref.commit;
    var username = loginInfo.username,
        password = loginInfo.password,
        vcode = loginInfo.vcode,
        verifyCodeKey = loginInfo.verifyCodeKey;
    return new Promise(function (resolve, reject) {
      (0, _basebasic.login)({
        userName: username.trim(),
        password: password,
        code: vcode,
        key: verifyCodeKey
      }).then(function (response) {
        if (response.code == 0) {
          var data = response.data;
          (0, _auth.setToken)(data.token.token); // setUid(data.token.userId);

          commit('SET_TOKEN', data.token.token);
        } // commit('SET_USER_ID', data.id)


        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  getUserInfo: function getUserInfo(_ref2) {
    var commit = _ref2.commit;
    return new Promise(function (resolve, reject) {
      (0, _basebasic.getUserInfo)().then(function (response) {
        if (response.code == 0) {
          (0, _basebasic.getIndexData)().then(function (res) {
            // console.log(res.data)
            var indexData = res.data; // setUid(indexData.id);
            // localStorage.clear()
            // localStorage.setItem('userInfo', JSON.stringify(indexData))
            // console.log(localStorage.getItem('userInfo'))

            commit('SET_NAME', indexData.nickName); // 显示账号名称

            commit('SET_HOSPITALNAME', indexData.hospitalName); // 显示医院名称
          });
          var data = response.data; // const avatar = data.HeadIcon === '' ? require('@/assets/images/profile.jpg') : process.env.VUE_APP_BASE_API + data.HeadIcon
          // commit('SET_TEMPNAME', data.Account)
          //  commit('SET_AVATAR', avatar)
          // commit('SET_ROLES', data.Role)
          //  commit('SET_SUBSYSTEM', data.SubSystemList)
          //  commit('SET_ACTIVESYSTEMNAME', data.ActiveSystem)

          commit('SET_MENUS', data); //commit('SET_NAME', data.Account)
          //commit('SET_PERMISSIONS', data.Modules)
        }

        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // 系统切换
  sysConnetLogin: function sysConnetLogin(_ref3, userInfo) {
    var commit = _ref3.commit;
    return new Promise(function (resolve, reject) {
      (0, _basebasic.sysConnect)(userInfo).then(function (response) {
        var data = response.ResData;
        commit('SET_TOKEN', data.AccessToken);
        (0, _auth.setToken)(data.AccessToken);
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  },
  // user logout
  logout: function logout(_ref4) {
    var commit = _ref4.commit,
        state = _ref4.state;
    return new Promise(function (resolve, reject) {
      (0, _auth.removeToken)(); // must remove  token  first

      (0, _router.resetRouter)();
      commit('RESET_STATE');
      resolve(); // logout(state.token).then(() => {
      //   removeToken() // must remove  token  first
      //   resetRouter()
      //   commit('RESET_STATE')
      //   resolve()
      // }).catch(error => {
      //   removeToken() // must remove  token  first
      //   resetRouter()
      //   commit('RESET_STATE')
      //   reject(error)
      // })
    });
  },
  // 刷新 token
  resetToken: function resetToken(_ref5) {
    var commit = _ref5.commit;
    var data = {
      'token': (0, _auth.getToken)()
    };
    (0, _auth.removeToken)(); // must remove  token  first

    return new Promise(function (resolve) {
      (0, _basebasic.refreshToken)(data).then(function (res) {
        var data = res.Data;
        commit('SET_TOKEN', data.AccessToken);
      });
      commit('RESET_STATE');
      resolve();
    });
  },
  userNowMeunsFun: function userNowMeunsFun(encode) {
    var code = encode;
    return new Promise(function (resolve, reject) {
      (0, _basebasic.getListMeunFuntionBymeunCode)({
        enCode: code
      }).then(function (response) {
        var data = response.ResData;
        localStorage.setItem('nursing_currentmenus', JSON.stringify(data));
        resolve(response);
      }).catch(function (error) {
        reject(error);
      });
    });
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;