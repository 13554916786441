"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _vuex = require("vuex");

var _loginLogoInit = _interopRequireDefault(require("@/assets/images/login-logo-init.png"));

var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));

//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'SidebarLogo',
  props: {
    collapse: {
      type: Boolean,
      required: true
    }
  },
  data: function data() {
    return {
      title: '管理中心',
      logo: _loginLogoInit.default
    };
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapGetters)(['activeSystemName'])), {}, {
    variables: function variables() {
      return _variables2.default;
    },
    sideTheme: function sideTheme() {
      return this.$store.state.settings.sideTheme;
    }
  })
};
exports.default = _default;