var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: { "has-logo": _vm.showLogo },
      style: {
        backgroundColor:
          _vm.settings.sideTheme === "theme-dark"
            ? _vm.variables.menuBg
            : _vm.variables.menuLightBg,
      },
    },
    [
      _c(
        "el-scrollbar",
        {
          class: _vm.settings.sideTheme,
          attrs: { "wrap-class": "scrollbar-wrapper" },
        },
        [
          _c("hamburger", {
            staticClass: "hamburger-container",
            attrs: { "is-active": _vm.sidebar.opened },
            on: { "toggle-click": _vm.toggleSideBar },
          }),
          _c(
            "el-menu",
            {
              staticClass: "el-menu-vertical-demo",
              attrs: {
                "default-active": _vm.activeMenu,
                "background-color":
                  _vm.settings.sideTheme === "theme-dark"
                    ? _vm.variables.menuBg
                    : _vm.variables.menuLightBg,
                "text-color":
                  _vm.settings.sideTheme === "theme-dark"
                    ? _vm.variables.menuText
                    : "rgba(255,255,255)",
                "unique-opened": true,
                "active-text-color": _vm.menuActive,
                "menu-trigger": "click",
              },
            },
            _vm._l(_vm.asideMenu, function (route) {
              return _c("sidebar-item", {
                key: route.path,
                attrs: { item: route, "base-path": route.path },
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }