"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _hasRole = _interopRequireDefault(require("./hasRole"));

var _hasPermi = _interopRequireDefault(require("./hasPermi"));

var install = function install(Vue) {
  Vue.directive('hasRole', _hasRole.default);
  Vue.directive('hasPermi', _hasPermi.default);
};

if (window.Vue) {
  window['hasRole'] = _hasRole.default;
  window['hasPermi'] = _hasPermi.default;
  Vue.use(install); // eslint-disable-line
}

var _default = install;
exports.default = _default;