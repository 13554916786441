"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.constantRoutes = void 0;
exports.resetRouter = resetRouter;

var _vue = _interopRequireDefault(require("vue"));

var _vueRouter = _interopRequireDefault(require("vue-router"));

var _layout = _interopRequireDefault(require("@/layout"));

var _ParentView = _interopRequireDefault(require("@/components/ParentView"));

var _promise = require("core-js/features/promise");

_vue.default.use(_vueRouter.default); // 获取原型对象上的push函数


var originalPush = _vueRouter.default.prototype.push; // 修改原型对象中的push方法

_vueRouter.default.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(function (err) {
    return err;
  });
};
/* Layout */


/**
 * 所有人都可以访问的路由
 */
var constantRoutes = [{
  path: '/print01',
  name: 'print01',
  component: function component(resolve) {
    return require(['@/views/print/index'], resolve);
  },
  hidden: true
}, {
  path: '/redirect',
  component: _layout.default,
  hidden: true,
  children: [{
    path: '/redirect/:path(.*)',
    component: function component(resolve) {
      return require(['@/views/redirect/index'], resolve);
    }
  }]
}, {
  path: '/login',
  name: 'login',
  component: function component(resolve) {
    return require(['@/views/login/index'], resolve);
  },
  hidden: true
}, {
  path: '/register',
  name: 'register',
  component: function component(resolve) {
    return require(['@/views/register/index'], resolve);
  },
  hidden: true
}, {
  path: '/404',
  name: 'Page404',
  component: function component(resolve) {
    return require(['@/views/error/404'], resolve);
  },
  hidden: true
}, {
  path: '/403',
  name: 'Page403',
  component: function component(resolve) {
    return require(['@/views/error/403'], resolve);
  },
  hidden: true
}, {
  path: '/print',
  name: 'print',
  component: function component(resolve) {
    return require(['@/views/order/print/index'], resolve);
  },
  hidden: true
}, {
  path: '/order/profitsharingDetails',
  name: 'profitsharingDetails',
  component: function component(resolve) {
    return require(['@/views/order/orderProfitsharing/details/details'], resolve);
  },
  hidden: true
}, {
  path: '/',
  redirect: '/dashboard',
  component: _layout.default,
  children: [{
    path: '/order/orderPatient',
    component: function component(resolve) {
      return require(['@/views/order/orderPatient/index'], resolve);
    },
    name: 'orderPatient',
    meta: {
      title: '核销订单',
      icon: 'dashboard',
      affix: false
    }
  }, {
    path: 'dashboard',
    name: 'Dashboard',
    component: function component(resolve) {
      return require(['@/views/dashboard/index'], resolve);
    },
    meta: {
      title: '首页',
      icon: 'dashboard',
      affix: false
    }
  }, {
    path: '/order/orderProfitsharing',
    component: function component(resolve) {
      return require(['@/views/order/orderProfitsharing/index'], resolve);
    },
    name: 'orderProfitsharing',
    meta: {
      title: '对账订单',
      icon: 'dashboard',
      affix: false
    }
  }, // {
  //     path: '/order/orderProfitsharing/details',
  //     component: (resolve) => require(['@/views/order/orderProfitsharing/details/details'], resolve),
  //     name: 'orderProfitsharingDetail',
  //     meta: { title: '对账订单详情', icon: 'dashboard', affix: false }
  // },
  {
    path: '/order/orderPhyical',
    component: function component(resolve) {
      return require(['@/views/order/orderPhyical/index'], resolve);
    },
    name: 'orderPhyical',
    meta: {
      title: '全部预约',
      icon: 'dashboard',
      affix: false
    }
  }, {
    path: '/order/orderPhyical/detail',
    component: function component(resolve) {
      return require(['@/views/order/orderPhyical/detail'], resolve);
    },
    name: 'orderPhyicalDetail',
    meta: {
      title: '预约信息',
      icon: 'dashboard',
      affix: false
    }
  }, {
    path: '/order/OrderDetail/index',
    name: 'OrderDetail',
    component: function component(resolve) {
      return require(['@/views/order/OrderDetail/index'], resolve);
    },
    meta: {
      title: '订单详情',
      icon: 'dashboard',
      affix: false
    }
  }, {
    path: '/usercenter/modify',
    name: 'Usercentermodify',
    component: function component(resolve) {
      return require(['@/views/usercenter/modify'], resolve);
    },
    meta: {
      title: '修改密码',
      icon: 'new-pwd'
    }
  }]
}, {
  path: '/usercenter',
  redirect: '/usercenter/index',
  component: _layout.default,
  meta: {
    title: '个人中心',
    icon: 'my'
  },
  children: [{
    path: '/usercenter/index',
    name: 'Usercenter',
    component: function component(resolve) {
      return require(['@/views/usercenter/index'], resolve);
    },
    meta: {
      title: '个人信息',
      icon: 'card'
    }
  }, {
    path: '/usercenter/modify',
    name: 'Usercentermodify',
    component: function component(resolve) {
      return require(['@/views/usercenter/modify'], resolve);
    },
    meta: {
      title: '修改密码',
      icon: 'new-pwd'
    }
  }]
} // 404 page must be placed at the end !!!
// { path: '*', redirect: '/404', hidden: true }
];
exports.constantRoutes = constantRoutes;

var createRouter = function createRouter() {
  return new _vueRouter.default({
    scrollBehavior: function scrollBehavior() {
      return {
        y: 0
      };
    },
    routes: constantRoutes
  });
};

var router = createRouter(); // Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465

function resetRouter() {
  var newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}

var _default = router;
exports.default = _default;