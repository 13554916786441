"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

require("core-js/modules/es.array.map.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/web.dom-collections.iterator.js");

var _vue = _interopRequireDefault(require("vue"));

var _SvgIcon = _interopRequireDefault(require("@/components/SvgIcon"));

// svg component
// register globally
_vue.default.component('SvgIcon', _SvgIcon.default);

var req = require.context('./svg', false, /\.svg$/);

var requireAll = function requireAll(requireContext) {
  return requireContext.keys().map(requireContext);
};

requireAll(req);