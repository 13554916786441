"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.loadView = exports.default = void 0;

require("core-js/modules/es.array.concat.js");

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.json.stringify.js");

require("core-js/modules/web.dom-collections.for-each.js");

require("core-js/modules/es.function.name.js");

var _router = require("@/router");

var _index = _interopRequireDefault(require("@/layout/index"));

var _ParentView = _interopRequireDefault(require("@/components/ParentView"));

var permission = {
  state: {
    routes: [],
    addRoutes: [],
    sidebarRouters: []
  },
  mutations: {
    SET_ROUTES: function SET_ROUTES(state, routes) {
      state.addRoutes = routes;
      state.routes = _router.constantRoutes.concat(routes);
    },
    SET_SIDEBAR_ROUTERS: function SET_SIDEBAR_ROUTERS(state, routers) {
      //state.sidebarRouters = constantRoutes.concat(routers)
      state.sidebarRouters = routers; //不增加默认菜单
    }
  },
  actions: {
    // 生成路由
    GenerateRoutes: function GenerateRoutes(_ref, meuns) {
      var commit = _ref.commit;
      return new Promise(function (resolve) {
        var menuList = JSON.stringify(meuns);
        var sdata = JSON.parse(menuList);
        var rdata = JSON.parse(menuList);
        var rewriteRoutes = [];
        var sidebarRoutes = filterAsyncRouter(sdata); //左侧菜单

        rewriteRoutes = filterAsyncRouter01(sdata); //路由
        // console.log(sidebarRoutes)

        rewriteRoutes.push({
          path: '*',
          redirect: '/404',
          hidden: true
        });
        rewriteRoutes.shift(); // commit('SET_ROUTES', rewriteRoutes)

        commit('SET_SIDEBAR_ROUTERS', sidebarRoutes);
        resolve(rewriteRoutes);
      });
    }
  }
}; // 遍历后台传来的路由字符串，转换为组件对象
//路由

function filterAsyncRouter01(asyncRouterMap) {
  var route = [];
  asyncRouterMap.forEach(function (el) {
    var a = {};

    if (el.jump == null && el.list.length > 0) {
      a.path = '/';
      a.component = _index.default;
      a.children = filterAsyncRouter01(el.list);
    } else {
      if (el.jump == '/') {} else {
        a.path = el.jump;
        a.name = el.title;
        a.component = loadView(el.jump);
        a.meta = {
          title: el.title,
          icon: el.icon
        };
      }
    }

    route.push(a);
  });
  return route;
}

function filterAsyncRouter(asyncRouterMap) {
  var isRewrite = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  var route = []; // console.log(asyncRouterMap)

  asyncRouterMap.forEach(function (el, index) {
    var a = {};

    if (el.list && el.list.length > 0) {
      a.children = filterAsyncRouter(el.list);
    }

    if (el.jump != null) {
      a.path = "/" + el.jump;

      if (el.jump == '/') {
        a.path = "/";
      }
    } else {
      a.path = null;
    }

    a.name = el.name + Math.random();
    a.meta = {
      title: el.title,
      icon: el.icon
    };
    route.push(a);
  });
  return route; // if (isRewrite && route.children) {
  //   route.children = filterChildren(route.children)
  // }
  // if (route.component) {
  //   if (route.component === 'Layout') {
  //     route.component = Layout
  //   } else if (route.component === 'ParentView') { // Layout ParentView 组件特殊处理
  //     route.component = ParentView
  //   } else {
  //     route.component = loadView(route.component)
  //   }
  // }
  // if (route.children != null && route.children && route.children.length) {
  //   route.children = filterAsyncRouter(route.children, route, isRewrite)
  // }
}

function filterChildren(childrenMap) {
  var children = [];
  childrenMap.forEach(function (el, index) {
    if (el.children && el.children.length) {
      if (el.component === 'ParentView') {
        el.children.forEach(function (c) {
          if (c.children && c.children.length) {
            children = children.concat(filterChildren(c.children, c));
            return;
          }

          children.push(c);
        });
        return;
      }
    }

    children = children.concat(el);
  });
  return children;
}

var loadView = function loadView(view) {
  // 路由懒加载
  return function (resolve) {
    return require(["@/views/".concat(view)], resolve);
  };
};

exports.loadView = loadView;
var _default = permission;
exports.default = _default;