"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.sign = exports.GetRandomString = void 0;

var _typeof2 = _interopRequireDefault(require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/typeof.js"));

var _createForOfIteratorHelper2 = _interopRequireDefault(require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/createForOfIteratorHelper.js"));

require("core-js/modules/es.array.sort.js");

require("core-js/modules/es.json.stringify.js");

require("core-js/modules/es.array.join.js");

var _jsMd = _interopRequireDefault(require("js-md5"));

/**
 * 获取一个32位随机字符串
 * @returns
 */
var GetRandomString = function GetRandomString() {
  var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz123456789';
  var maxPos = chars.length;
  var pwd = '';

  for (var i = 0; i < 32; i++) {
    pwd += chars.charAt(Math.floor(Math.random() * maxPos));
  }

  return pwd;
};
/**
* 签名
* @param config 请求配置
* @param nonce 随机字符串
* @param timestamp 签名时间戳
* @param appSecret 应用密钥
* @param method 请求方式
*/


exports.GetRandomString = GetRandomString;

var sign = function sign(config, nonce, timestamp, appSecret) {
  // 签名格式： timestamp + nonce + data(字典升序)+appSecret
  var ret = [];

  if (config.params) {
    var pArray = [];
    var data = config.params;

    for (var it in data) {
      pArray.push(it);
    } // 字典升序


    var sArray = pArray.sort();

    var _iterator = (0, _createForOfIteratorHelper2.default)(sArray),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _it = _step.value;
        var val = data[_it];

        if ((0, _typeof2.default)(val) === 'object' && ( //
        !(val instanceof Array) || val.length > 0 && (0, _typeof2.default)(val[0]) === 'object')) {
          val = JSON.stringify(val);
        }

        ret.push(_it + val);
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  } else {
    ret.push(JSON.stringify(config.data));
  }

  var signsrc = timestamp + nonce + ret.join('') + appSecret;
  return (0, _jsMd.default)(signsrc);
};

exports.sign = sign;