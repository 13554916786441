"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var _axios = _interopRequireDefault(require("axios"));

var _router = _interopRequireDefault(require("../router"));

var _elementUi = require("element-ui");

var _store = _interopRequireDefault(require("@/store"));

var _auth = require("@/utils/auth");

var _cmrs = require("@/utils/cmrs");

// create an axios instance
var service = _axios.default.create({
  //  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 50000 // request timeout

});

service.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'; //service.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'
// request拦截器

service.interceptors.request.use(function (config) {
  if (config.headers['Content-Type'] === undefined) {
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
  }

  var token = (0, _auth.getToken)();

  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token;
  } // 如果接口需要签名, 则通过请求时,headers中传递sign参数true


  var iSSign = config.headers['sign'];

  if (iSSign || iSSign === undefined) {
    var timeStamp = new Date().getTime().toString().substr(0, 10);
    var nonce = (0, _cmrs.GetRandomString)();
    config.headers['appId'] = _store.default.getters.appId;
    config.headers['nonce'] = nonce;
    config.headers['timeStamp'] = timeStamp;
    config.headers['signature'] = (0, _cmrs.sign)(config, nonce, timeStamp, _store.default.getters.appSecret);
  }

  return config;
}, function (error) {
  // do something with request error
  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(function (response) {
  var res = response.data; // console.log('resssssssssssss', res)

  if (res.code === 401) {
    // 超时自动刷新token
    _store.default.dispatch('user/resetToken').then(function (res) {
      location.reload();
    });
  }

  if (res.code > 0) {
    (0, _elementUi.Message)({
      message: res.msg,
      type: 'warning'
    });
    return res;
  }

  return res;
}, function (error) {
  console.log('err=' + error);
  var message = error.message;

  if (message === 'Network Error') {
    message = '后端接口连接异常';
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时';
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常';
  }

  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;