"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.function.name.js");

var _basebasic = require("@/api/basebasic");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  name: 'DialogPatientVerify',
  props: {
    dialogTitle: {
      type: String
    },
    dialogData: {
      type: Object
    },
    dialogShow: {
      type: Boolean
    }
  },
  inject: ['reload'],
  data: function data() {
    return {
      patient: {},
      barCodeNo: '',
      dialogVerifyVisible: false,
      size: '45px',
      dialogShowTitle: '',
      formWidth: "100px"
    };
  },
  created: function created() {
    this.patient = this.dialogData;
    this.dialogShowTitle = this.dialogTitle;
    this.dialogVerifyVisible = this.dialogShow;
  },
  watch: {
    dialogData: function dialogData() {
      this.patient = this.dialogData;
      this.dialogVerifyVisible = this.dialogShow;
      this.dialogShowTitle = this.dialogTitle;
      this.barCodeNo = this.patient.thirdBarCode;
      console.log(this.patient);
    },
    dialogShow: function dialogShow() {
      this.dialogVerifyVisible = this.dialogShow;
    }
  },
  mounted: function mounted() {
    /* istanbul ignore if */
    this.dialogVerifyVisible = this.dialogShow;
  },
  methods: {
    verify: function verify(data) {
      var _this = this;

      this.barCodeNo = data.barCodeNo;

      if (data.thirdApplyStatus === 2 && (this.barCodeNo === '' || this.barCodeNo === null)) {
        this.$alert('条码必须填写哦');
        return;
      }

      var verifyData = {
        "orderId": data.orderId,
        "verifyCode": data.verifyCode,
        "barCodeNo": this.barCodeNo
      };
      (0, _basebasic.PatientVerify)(verifyData).then(function (res) {
        // PatientVerify函数开始
        if (res.code === 0) {
          console.log(res);
          console.log(data);
          console.log('核销ok');

          _this.$message({
            type: 'success',
            message: res.msg
          });
          /*
          * printSerialNo
          */


          var printName = data.name;
          var printSex = data.sexText;
          var printAge = data.age;
          var printVerifyCode = data.verifyCode; // TODO thirdApplyStatus 先全部不打印，以后设定一个值来判断要不要打印
          // if(printVerifyCode !== '' && data.thirdApplyStatus === 2){
          // 	let routeData = this.$router.resolve({
          // 			path: '/print',
          // 			query: { printVerifyCode, printName, printSex, printAge }
          // 		})
          // 	window.open(routeData.href, '_blank')
          // }
        } else {
          _this.$message({
            type: 'error',
            message: res.msg
          });
        }

        _this.dialogVerifyVisible = false;

        _this.reload();
      }); // PatientVerify函数结束	
    },
    handleClose: function handleClose(done) {
      var _this2 = this;

      this.$confirm('确认关闭核销框吗？').then(function (_) {
        _this2.dialogVerifyVisible = false;

        _this2.reload();

        done();
      }).catch(function (_) {});
    }
  }
};
exports.default = _default;