"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.function.name.js");

var _auth = require("@/utils/auth");

var getters = {
  sidebar: function sidebar(state) {
    return state.app.sidebar;
  },
  device: function device(state) {
    return state.app.device;
  },
  visitedViews: function visitedViews(state) {
    return state.tagsView.visitedViews;
  },
  cachedViews: function cachedViews(state) {
    return state.tagsView.cachedViews;
  },
  token: function token(state) {
    return state.user.token;
  },
  avatar: function avatar(state) {
    return state.user.avatar;
  },
  name: function name(state) {
    return state.user.name;
  },
  hospitalName: function hospitalName(state) {
    return state.user.hospitalName;
  },
  user_id: function user_id(state) {
    return (0, _auth.getUid)();
  },
  roles: function roles(state) {
    return state.user.roles;
  },
  subSystem: function subSystem(state) {
    return state.user.subSystem;
  },
  activeSystemName: function activeSystemName(state) {
    return state.user.activeSystemName;
  },
  menus: function menus(state) {
    return state.user.menus;
  },
  permissions: function permissions(state) {
    return state.user.permissions;
  },
  permission_routes: function permission_routes(state) {
    return state.permission.routes;
  },
  addRouters: function addRouters(state) {
    return state.settings.addRouters;
  },
  sidebarRouters: function sidebarRouters(state) {
    return state.permission.sidebarRouters;
  },
  appId: function appId(state) {
    return state.settings.appId;
  },
  appSecret: function appSecret(state) {
    return state.settings.appSecret;
  }
};
var _default = getters;
exports.default = _default;