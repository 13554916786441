"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

/**
 * 防止重复点击
 * @author OK
 */
var _default = {
  install: function install(Vue) {
    // 防止重复点击
    Vue.directive('preventReClick', {
      inserted: function inserted(el, binding) {
        el.addEventListener('click', function () {
          if (!el.disabled) {
            el.disabled = true;
            setTimeout(function () {
              el.disabled = false;
            }, binding.value || 1000);
          }
        });
      }
    });
  }
};
exports.default = _default;