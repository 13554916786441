"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es.object.to-string.js");

require("core-js/modules/es.regexp.to-string.js");

require("core-js/modules/es.array.includes.js");

require("core-js/modules/es.string.includes.js");

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var _axios = _interopRequireDefault(require("axios"));

var _settings = _interopRequireDefault(require("@/settings"));

var _uuid = require("uuid");

var _cryptoJs = _interopRequireDefault(require("crypto-js"));

var _elementUi = require("element-ui");

//对接腾讯接口
var service = _axios.default.create({
  baseURL: process.env.VUE_APP_TxapiHostUrl,
  //baseURL:defaultSettings.TxapiHostUrl , 
  timeout: 50000 // request timeout

});

service.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
var partnerSecrect = _settings.default.partnerSecrect; // 合作方密钥正式版
//const partnerSecrect = "c326c066322edf012790c20e4ebfdb60"; // 合作方密钥测试版

var partnerId = "100000425"; // 合作方ID

service.interceptors.request.use(function (config) {
  var iSSign = config.headers['sign'];

  if (iSSign || iSSign === undefined) {
    // console.log(partnerId)
    // console.log(partnerSecrect)
    var timeStamp = +new Date();
    config.headers['god-portal-request-id'] = (0, _uuid.v4)();
    config.headers['god-portal-timestamp'] = timeStamp;
    config.headers['god-portal-signature'] = _cryptoJs.default.HmacSHA256(partnerId + timeStamp, partnerSecrect).toString(); //hex string
  }

  return config;
}, function (error) {
  // do something with request error
  return Promise.reject(error);
});
service.interceptors.response.use(function (response) {
  var res = response.data; // if (res.code === 401) { // 超时自动刷新token
  //   store.dispatch('user/resetToken').then((res) => {
  //     location.reload()
  //   })
  // }

  if (res.code !== 0) {
    (0, _elementUi.Message)({
      message: res.message,
      type: 'warning'
    });
    return res;
  }

  return res;
}, function (error) {
  console.log('err=' + error);
  var message = error.message;

  if (message === 'Network Error') {
    message = '后端接口连接异常';
  } else if (message.includes('timeout')) {
    message = '系统接口请求超时';
  } else if (message.includes('Request failed with status code')) {
    message = '系统接口' + message.substr(message.length - 3) + '异常';
  }

  (0, _elementUi.Message)({
    message: error.message,
    type: 'error',
    duration: 5 * 1000
  });
  return Promise.reject(error);
});
var _default = service;
exports.default = _default;