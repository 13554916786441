var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "sidebar-logo-container",
      class: { collapse: _vm.collapse },
      style: {
        backgroundColor:
          _vm.sideTheme === "theme-dark"
            ? _vm.variables.menuBg
            : _vm.variables.LogoLightBg,
      },
    },
    [
      _c("transition", { attrs: { name: "sidebarLogoFade" } }, [
        _c("div", { staticClass: "sidebar-logo-link" }, [
          _c("img", {
            staticClass: "sidebar-logo",
            attrs: { src: require("@/assets/images/login-logo-init.png") },
          }),
          _c(
            "h1",
            {
              staticClass: "sidebar-title",
              style: {
                color:
                  _vm.sideTheme === "theme-dark"
                    ? _vm.variables.sidebarTitle
                    : _vm.variables.sidebarLightTitle,
              },
            },
            [_vm._v(_vm._s(_vm.activeSystemName + _vm.title) + " ")]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }