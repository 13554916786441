var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      ref: "dialogVerify",
      attrs: {
        title: _vm.dialogShowTitle + "核销信息确认",
        visible: _vm.dialogVerifyVisible,
        "before-close": _vm.handleClose,
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        width: "800px",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVerifyVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "patient",
          staticClass: "demo-form-inline",
          attrs: { model: _vm.patient, required: "true" },
        },
        [
          _c(
            "el-descriptions",
            {
              staticClass: "margin-top",
              attrs: { column: 3, size: _vm.size, border: "" },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-user" }),
                    _vm._v(" 姓名 "),
                  ]),
                  _vm._v(" " + _vm._s(_vm.patient.name)),
                  _c("el-tag", { attrs: { size: "small", type: "success" } }, [
                    _vm._v(_vm._s(_vm.patient.sexText)),
                  ]),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-mobile-phone" }),
                    _vm._v(" 手机号 "),
                  ]),
                  _vm._v(" " + _vm._s(_vm.patient.phone) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-time" }),
                    _vm._v(" 预约时间 "),
                  ]),
                  _vm._v(
                    " " +
                      _vm._s(_vm.patient.medicalDate) +
                      " " +
                      _vm._s(_vm.patient.medicalTimeSpan) +
                      " "
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-date" }),
                    _vm._v(" 年龄 "),
                  ]),
                  _c(
                    "div",
                    { staticClass: "tag-group" },
                    [
                      _c(
                        "el-tag",
                        { attrs: { effect: "dark", type: "success" } },
                        [
                          _vm._v(
                            _vm._s(_vm.patient.age) +
                              "岁(" +
                              _vm._s(_vm.patient.marriageStatusText) +
                              ")"
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-bank-card" }),
                    _vm._v(" " + _vm._s(_vm.patient.cardTypeText) + " "),
                  ]),
                  _vm._v(" " + _vm._s(_vm.patient.cardId) + " "),
                ],
                2
              ),
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-key" }),
                    _vm._v(" 核销码 "),
                  ]),
                  _vm._v(" " + _vm._s(_vm.patient.verifyCode) + " "),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "el-descriptions",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.patient.thirdApplyStatus === 2,
                  expression: "patient.thirdApplyStatus === 2",
                },
              ],
              staticClass: "margin-top",
              attrs: { column: 3, border: "" },
            },
            [
              _c(
                "el-descriptions-item",
                [
                  _c("template", { slot: "label" }, [
                    _c("i", { staticClass: "el-icon-s-ticket" }),
                    _vm._v(" LIS条码 "),
                  ]),
                  _c("el-input", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.patient.thirdApplyStatus === 2,
                        expression: "patient.thirdApplyStatus === 2",
                      },
                    ],
                    attrs: {
                      autofocus: "true",
                      "aria-required": "true",
                      placeholder: "请输入lis条码",
                      clearable: "",
                    },
                    model: {
                      value: _vm.patient.barCodeNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.patient, "barCodeNo", $$v)
                      },
                      expression: "patient.barCodeNo",
                    },
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c("el-divider", [
            _vm._v(" " + _vm._s(_vm.patient.packageName) + " "),
          ]),
          _c("el-divider", [
            _vm._v(" 订单" + _vm._s(_vm.patient.orderId) + " "),
          ]),
          _c(
            "el-form-item",
            { staticClass: "footer self-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "info", round: "" },
                  on: { click: _vm.handleClose },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "danger", round: "" },
                  on: {
                    click: function ($event) {
                      return _vm.verify(_vm.patient)
                    },
                  },
                },
                [_vm._v("确 定 核 销")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }