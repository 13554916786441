"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.deleteSoftUser = deleteSoftUser;
exports.deleteUser = deleteUser;
exports.getByUserName = getByUserName;
exports.getUserDetail = getUserDetail;
exports.getUserListWithPager = getUserListWithPager;
exports.getUserType = getUserType;
exports.modifyPassword = modifyPassword;
exports.registerUser = registerUser;
exports.resetPassword = resetPassword;
exports.saveThemeConfig = saveThemeConfig;
exports.saveUser = saveUser;
exports.setUserEnable = setUserEnable;

var _request = _interopRequireDefault(require("@/utils/request"));

var _settings = _interopRequireDefault(require("@/settings"));

/**
   * 用户类型查询
   *
   */
function getUserType() {
  return _request.default.request({
    url: 'User/GetUserType',
    method: 'get',
    baseURL: _settings.default.apiSecurityUrl // 直接通过覆盖的方式

  });
}
/**
   * 用户分页查询
   * @param {查询条件} data
   */


function getUserListWithPager(data) {
  return _request.default.request({
    url: 'User/FindWithPagerSearchAsync',
    method: 'post',
    data: data,
    baseURL: _settings.default.apiSecurityUrl // 直接通过覆盖的方式

  });
}
/**
   * 新增或修改保存用户
   * @param data
   */


function saveUser(data, url) {
  return _request.default.request({
    url: url,
    method: 'post',
    data: data,
    baseURL: _settings.default.apiSecurityUrl // 直接通过覆盖的方式

  });
}
/**
   * 获取用户详情
   * @param {Id} 用户Id
   */


function getUserDetail(id) {
  return (0, _request.default)({
    url: 'User/GetById',
    method: 'get',
    params: {
      id: id
    },
    baseURL: _settings.default.apiSecurityUrl // 直接通过覆盖的方式

  });
}
/**
   * 获取用户详情
   * @param {userName} 用户账号
   */


function getByUserName(userName) {
  return (0, _request.default)({
    url: 'Tools/GetUserInfo',
    method: 'post',
    params: {
      userName: userName
    },
    baseURL: _settings.default.apiHostUrl // 直接通过覆盖的方式

  });
}
/**
   * 批量设置启用状态
   * @param {id集合} ids
   */


function setUserEnable(data) {
  return (0, _request.default)({
    url: 'User/SetEnabledMarktBatchAsync',
    method: 'post',
    data: data,
    baseURL: _settings.default.apiSecurityUrl // 直接通过覆盖的方式

  });
}
/**
   * 批量软删除
   * @param {id集合} ids
   */


function deleteSoftUser(data) {
  return (0, _request.default)({
    url: 'User/DeleteSoftBatchAsync',
    method: 'post',
    data: data,
    baseURL: _settings.default.apiSecurityUrl // 直接通过覆盖的方式

  });
}
/**
   * 批量删除
   * @param {id集合} ids
   */


function deleteUser(data) {
  return (0, _request.default)({
    url: 'User/DeleteBatchAsync',
    method: 'delete',
    data: data,
    baseURL: _settings.default.apiSecurityUrl // 直接通过覆盖的方式

  });
}
/**
   * 重置密码
   * @param {userId:用户id} data
   */


function resetPassword(data) {
  return (0, _request.default)({
    url: 'User/ResetPassword',
    method: 'post',
    params: data,
    baseURL: _settings.default.apiSecurityUrl // 直接通过覆盖的方式

  });
}
/**
   * 修改密码
   * @param {password:新密码,password2:重复新密码} data
   */


function modifyPassword(data) {
  return (0, _request.default)({
    url: 'User/ModifyPassword',
    method: 'post',
    params: data,
    baseURL: _settings.default.apiSecurityUrl // 直接通过覆盖的方式

  });
}
/**
   * 注册新用户
   * @param data
   */


function registerUser(data, url) {
  return _request.default.request({
    url: 'User/Register',
    method: 'post',
    data: data,
    baseURL: _settings.default.apiSecurityUrl // 直接通过覆盖的方式

  });
}
/**
   * 保存用户主题配置
   * @param data
   */


function saveThemeConfig(data) {
  return _request.default.request({
    url: 'User/SaveUserTheme',
    method: 'post',
    data: data,
    baseURL: _settings.default.apiSecurityUrl // 直接通过覆盖的方式

  });
}