"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _objectSpread2 = _interopRequireDefault(require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/objectSpread2.js"));

var _vuex = require("vuex");

var _Logo = _interopRequireDefault(require("./Logo"));

var _SidebarItem = _interopRequireDefault(require("./SidebarItem"));

var _variables2 = _interopRequireDefault(require("@/styles/variables.scss"));

var _Hamburger = _interopRequireDefault(require("@/components/Hamburger"));

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      menuActive: '#fff',
      asideMenu: [{
        path: '/dashboard',
        name: '/dashboard',
        meta: {
          title: '首页',
          icon: 'el-icon-s-home',
          affix: true
        }
      }, //   {
      //     path: '/',
      //     name:'index',
      //     meta: { title: '主页', icon: 'el-icon-s-fold', affix: true },
      //     children:[{
      //             path:'/dashboard',
      //              name:'/dashboard',
      //              meta: { title: '控制台', icon: 'el-icon-s-fold', affix: true },
      //     }
      //     ]
      // },
      //{
      //   path: '/', 
      //   name:'订单管理',
      //   meta: { title: '订单管理', icon: 'el-icon-s-fold', affix: true },
      //   children:[
      //     ]
      // },
      {
        path: '/order/orderPatient',
        name: '核销订单',
        meta: {
          title: '核销管理',
          icon: 'el-icon-key',
          affix: true
        }
      }, {
        path: '/order/orderProfitsharing',
        name: '对账管理',
        meta: {
          title: '对账管理',
          icon: 'el-icon-money',
          affix: true
        }
      }, {
        path: '/order/orderPhyical',
        name: '全部预约',
        meta: {
          title: '全部预约',
          icon: 'el-icon-s-grid',
          affix: true
        }
      }]
    };
  },
  components: {
    SidebarItem: _SidebarItem.default,
    Logo: _Logo.default,
    Hamburger: _Hamburger.default
  },
  computed: (0, _objectSpread2.default)((0, _objectSpread2.default)((0, _objectSpread2.default)({}, (0, _vuex.mapState)(['settings'])), (0, _vuex.mapGetters)(['permission_routes', 'sidebarRouters', 'sidebar'])), {}, {
    routes: function routes() {//   return this.$router.options.routes
    },
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
          path = route.path; // if set path, the sidebar will highlight the path you set

      if (meta.activeMenu) {
        return meta.activeMenu;
      }

      return path;
    },
    showLogo: function showLogo() {
      return this.$store.state.settings.sidebarLogo;
    },
    variables: function variables() {
      return _variables2.default;
    },
    isCollapse: function isCollapse() {
      // return !this.sidebar.opened
      return true;
    }
  }),
  methods: {
    toggleSideBar: function toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    }
  }
};
exports.default = _default;