module.exports = {
  title: '溯医(INITMEDICAL)核销管理',

  /**
   * 侧边栏主题 深色主题theme-dark，浅色主题theme-light
   */
  sideTheme: 'theme-dark',

  /**
   * 是否系统布局配置
   */
  showSettings: false,

  /**
   * 是否显示 tagsView
   */
  tagsView: true,

  /**
   *是否固定头部
   */
  fixedHeader: true,

  /**
   * 是否显示侧边Logo
   */
  sidebarLogo: false,
  ver: process.env.VUE_APP_version,

  /**
   * 应用Id
   */
  appId: 'system',

  /**
   * 应用密钥
   */
  appSecret: '87135AB0160F706D8B47F06BDABA6FC6',

  /**
   * 子系统
   */
  subSystem: {},

  /**
   * 当前访问系统代码
   */
  activeSystemCode: 'Web_FIX',

  /**
   * 当前访问系统名称
   */
  activeSystemName: '',

  /**
   * 动态可访问路由
   */
  addRouters: {},
  partnerSecrect: process.env.VUE_APP_partnerSecrect,
  //合作方密钥 腾讯

  /**
   * 基础接口
   */
  apiHostUrl: process.env.VUE_APP_apiHostUrl,
  //api地址
  TxapiHostUrl: process.env.VUE_APP_TxapiHostUrl,
  //对接api 

  /**
   * 权限管理系统接口
   */
  apiSecurityUrl: 'https://nursing.ynyyxy.com/api/Security/',

  /**
   *文章
   */
  apiCMSUrl: 'https://nursing.ynyyxy.com/api/CMS/',

  /**
   *通用功能
   */
  apiCOMUrl: 'https://nursing.ynyyxy.com/api/Common/',

  /**
   * 文件访问路径
   */
  reportFileUrl: process.env.VUE_APP_reportFileUrl,
  //检查报告pdf路径
  fileUrl: process.env.VUE_APP_fileUrl,
  //主要是Excel文件

  /**
   * 文件上传路径
   */
  fileUploadUrl: '/api/OrderReport/uploadReport'
};