var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _vm._m(0),
    _c(
      "div",
      { staticClass: "right-menu" },
      [
        _c(
          "el-dropdown",
          {
            staticClass: "avatar-container right-menu-item",
            attrs: { trigger: "click" },
          },
          [
            _c("div", { staticClass: "avatar-wrapper" }, [
              _c("i", {
                staticClass: "el-icon-user-solid",
                staticStyle: { "margin-right": "10px" },
              }),
              _vm._v(_vm._s(_vm.name) + " "),
              _c("i", { staticClass: "el-icon-arrow-down" }),
            ]),
            _c(
              "el-dropdown-menu",
              {
                staticClass: "user-dropdown",
                attrs: { slot: "dropdown" },
                slot: "dropdown",
              },
              [
                _c(
                  "el-dropdown-item",
                  {
                    attrs: { divided: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.resetPassword.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { staticStyle: { display: "block" } }, [
                      _c("i", { staticClass: "el-icon-info" }),
                      _vm._v("修改密码"),
                    ]),
                  ]
                ),
                _c(
                  "el-dropdown-item",
                  {
                    attrs: { divided: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.logout.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("span", { staticStyle: { display: "block" } }, [
                      _c("i", { staticClass: "el-icon-remove" }),
                      _vm._v("退出"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("p", { staticClass: "hospital" }, [
      _vm._v(" 溯医(INITMEDICAL)核销管理 "),
      _c("span", { staticClass: "version" }, [
        _c("sup", { staticClass: "vers" }, [
          _vm._v(" " + _vm._s(_vm.version) + _vm._s(this.vers)),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sideba-logo-container" }, [
      _c("div", { staticClass: "sidebar-logo-link" }, [
        _c("img", {
          staticClass: "sidebar-logo",
          attrs: { src: require("@/assets/images/login-logo-init-withe.png") },
        }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }