"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

require("E:\\GsSoftProject\\projects\\Nursing.Net.Web.ThirdUI\\node_modules\\core-js\\modules\\es.array.iterator.js");

require("E:\\GsSoftProject\\projects\\Nursing.Net.Web.ThirdUI\\node_modules\\core-js\\modules\\es.promise.js");

require("E:\\GsSoftProject\\projects\\Nursing.Net.Web.ThirdUI\\node_modules\\core-js\\modules\\es.object.assign.js");

require("E:\\GsSoftProject\\projects\\Nursing.Net.Web.ThirdUI\\node_modules\\core-js\\modules\\es.promise.finally.js");

var _vue = _interopRequireDefault(require("vue"));

var _jsCookie = _interopRequireDefault(require("js-cookie"));

require("normalize.css/normalize.css");

var _sweetalert = _interopRequireDefault(require("sweetalert"));

var _elementUi = _interopRequireDefault(require("element-ui"));

require("@/styles/index.scss");

require("@/styles/css/layui.css");

var _App = _interopRequireDefault(require("./App"));

var _store = _interopRequireDefault(require("./store"));

require("element-ui/lib/theme-chalk/index.css");

var _router = _interopRequireDefault(require("./router"));

var _permission = _interopRequireDefault(require("./directive/permission"));

require("@/icons");

require("font-awesome/scss/font-awesome.scss");

require("@/permission");

var _index3 = require("@/utils/index");

var _common = _interopRequireDefault(require("@/utils/common"));

var _preventRepeatClick = _interopRequireDefault(require("@/utils/preventRepeatClick.js"));

var _dayjs = _interopRequireDefault(require("dayjs"));

require("dayjs/locale/zh-cn");

var _patientVerify = _interopRequireDefault(require("./components/patientVerify/patient-verify"));

// A modern alternative to CSS resets
// global css
// layui
// icon
// 图标
// permission control
//dayjs
_dayjs.default.locale("zh-cn"); // 全局方法挂载


_vue.default.prototype.resetForm = _index3.resetForm;
_vue.default.prototype.regFenToYuan = _index3.regFenToYuan;
_vue.default.prototype.formatBytes = _index3.formatBytes;
_vue.default.prototype.swel = _sweetalert.default;

_vue.default.use(_permission.default);

_vue.default.use(_preventRepeatClick.default);

if (process.env.NODE_ENV === 'production') {// const { mockXHR } = require('../mock')
  // mockXHR()
}

_vue.default.use(_elementUi.default, {
  size: _jsCookie.default.get('size') || 'medium' // set element-ui default size

});

// 核销弹出框
_vue.default.config.productionTip = false;

_vue.default.component('DialogPatientVerify', _patientVerify.default);

_vue.default.prototype.base = _common.default;
new _vue.default({
  el: '#app',
  router: _router.default,
  store: _store.default,
  render: function render(h) {
    return h(_App.default);
  }
});