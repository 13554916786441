"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _elementVariables = _interopRequireDefault(require("@/styles/element-variables.scss"));

var _settings = _interopRequireDefault(require("@/settings"));

var sideTheme = _settings.default.sideTheme,
    showSettings = _settings.default.showSettings,
    tagsView = _settings.default.tagsView,
    fixedHeader = _settings.default.fixedHeader,
    sidebarLogo = _settings.default.sidebarLogo,
    appId = _settings.default.appId,
    appSecret = _settings.default.appSecret,
    subSystem = _settings.default.subSystem,
    activeSystemCode = _settings.default.activeSystemCode,
    activeSystemName = _settings.default.activeSystemName,
    addRouters = _settings.default.addRouters;
var state = {
  theme: _elementVariables.default.theme,
  sideTheme: sideTheme,
  showSettings: showSettings,
  tagsView: tagsView,
  fixedHeader: fixedHeader,
  sidebarLogo: sidebarLogo,
  appId: appId,
  appSecret: appSecret,
  subSystem: subSystem,
  activeSystemCode: activeSystemCode,
  activeSystemName: activeSystemName,
  addRouters: addRouters,
  version: process.env.version
};
var mutations = {
  CHANGE_SETTING: function CHANGE_SETTING(state, _ref) {
    var key = _ref.key,
        value = _ref.value;

    // eslint-disable-next-line no-prototype-builtins
    if (state.hasOwnProperty(key)) {
      state[key] = value;
    }
  }
};
var actions = {
  changeSetting: function changeSetting(_ref2, data) {
    var commit = _ref2.commit;
    commit('CHANGE_SETTING', data);
  },

  /**
   * 加载用户配置主题
   * @param {} param
   * @param {*} data
   */
  loadUserSettingTheme: function loadUserSettingTheme(_ref3, data) {
    var commit = _ref3.commit;

    if (data !== 'default') {
      var userTheme = JSON.parse(data);
      state.theme = userTheme.Theme;
      state.sideTheme = userTheme.SideTheme;
      state.fixedHeader = userTheme.FixedHeader;
      state.tagsView = userTheme.TagsView;
      state.sidebarLogo = userTheme.SidebarLogo;
    }
  }
};
var _default = {
  namespaced: true,
  state: state,
  mutations: mutations,
  actions: actions
};
exports.default = _default;