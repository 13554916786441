"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _ThemePicker = _interopRequireDefault(require("@/components/ThemePicker"));

var _userservice = require("@/api/security/userservice");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  components: {
    ThemePicker: _ThemePicker.default
  },
  data: function data() {
    return {};
  },
  computed: {
    theme: function theme() {
      return this.$store.state.settings.theme;
    },
    sideTheme: function sideTheme() {
      return this.$store.state.settings.sideTheme;
    },
    fixedHeader: {
      get: function get() {
        return this.$store.state.settings.fixedHeader;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'fixedHeader',
          value: val
        });
      }
    },
    tagsView: {
      get: function get() {
        return this.$store.state.settings.tagsView;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'tagsView',
          value: val
        });
      }
    },
    sidebarLogo: {
      get: function get() {
        return this.$store.state.settings.sidebarLogo;
      },
      set: function set(val) {
        this.$store.dispatch('settings/changeSetting', {
          key: 'sidebarLogo',
          value: val
        });
      }
    }
  },
  methods: {
    themeChange: function themeChange(val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'theme',
        value: val
      });
    },
    handleTheme: function handleTheme(val) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'sideTheme',
        value: val
      });
    },
    handleSaveTheme: function handleSaveTheme() {
      var _this = this;

      var data = {
        Theme: this.$store.state.settings.theme,
        SideTheme: this.$store.state.settings.sideTheme,
        FixedHeader: this.$store.state.settings.fixedHeader,
        TagsView: this.$store.state.settings.tagsView,
        SidebarLogo: this.$store.state.settings.sidebarLogo
      };
      (0, _userservice.saveThemeConfig)(data).then(function (res) {
        if (res.Success) {
          _this.$message({
            message: '恭喜你，操作成功',
            type: 'success'
          });
        } else {
          _this.$message({
            message: res.ErrMsg,
            type: 'error'
          });
        }
      });
    }
  }
};
exports.default = _default;