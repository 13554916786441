"use strict";

var _interopRequireDefault = require("E:/GsSoftProject/projects/Nursing.Net.Web.ThirdUI/node_modules/@babel/runtime/helpers/interopRequireDefault.js").default;

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getToken = getToken;
exports.getToken01 = getToken01;
exports.getUid = getUid;
exports.removeToken = removeToken;
exports.setToken = setToken;
exports.setUid = setUid;

var _jsCookie = _interopRequireDefault(require("js-cookie"));

var TokenKey = 'cmrs_soft_token';

function getToken() {
  return _jsCookie.default.get(TokenKey);
}

function getToken01() {
  return _jsCookie.default.get(TokenKey);
}

function setToken(token) {
  return _jsCookie.default.set(TokenKey, token);
}

function getUid() {
  return _jsCookie.default.get('uid');
}

function setUid(id) {
  return _jsCookie.default.set('uid', id);
}

function removeToken() {
  return _jsCookie.default.remove(TokenKey);
}